import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import Store from '../store';


export function reloadTranslations(translations) {
  try {
    Store.dispatch('translations/change', translations);
  } catch (error) {
    return error;
  }
  return true;
}

export function trans(val, data) {
  let translated = Store.getters['translations/get'](val);

  if (isString(translated) && data && isObject(data)) {
    // support for %{placeholder}
    translated = translated.replace(/\%\{(\w+)\}/g, (placeholder, plainPlaceholder) => ((data[plainPlaceholder]) ? Store.getters['translations/get'](data[plainPlaceholder]) : placeholder));
    // support for :placeholder
    translated = translated.replace(/\:(\w+)/g, (placeholder, plainPlaceholder) => ((data[plainPlaceholder]) ? Store.getters['translations/get'](data[plainPlaceholder]) : placeholder));
  }

  return translated;
}

export default {
  methods: {
    // String Translation
    t(val, data) {
      return trans(val, data);
    },
  },
};
