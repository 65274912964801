import Incident from '../../api/main/Incident';
import { formStatus, mutations } from '../lib/Forms';
import router from '../router';

const api = new Incident();

export default {
  namespaced: true,
  state: {
    form: {
      reporter: {
        type: null,
        name: null,
        email: null,
        agreement: false,
        contactPolice: null,
        description: null,
      },
      incident: {
        location: null,
        date: null,
        locationDisplay: null,
        summary: null,
      },
      custom: {
        text: null,
        checkbox: null,
      },
      files: null,
      reCaptcha: null,
    },
    formStatus: formStatus(),
  },
  getters: {
    form(state) {
      return state.form;
    },
    formStatus(state) {
      return state.formStatus;
    },
  },
  mutations: {
    ...mutations('formStatus'),
  },
  actions: {
    send(context, payload) {
      context.dispatch('loading', true, { root: true });
      api.create(payload)
        .then((response) => {
          context.commit('formStatusSuccess', response);
          context.dispatch('loading', false, { root: true });
          router.push('/report-success');
        })
        .catch((error) => {
          context.commit('formStatusError', error);
          context.dispatch('loading', false, { root: true });
        });
    },
  },
};
