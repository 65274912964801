import Vue from 'vue';
import { mapGetters } from 'vuex';
import Vuetify from 'vuetify';
import VueMarkdown from 'vue-markdown';
import VeeValidate, { Validator } from 'vee-validate';
import router from './router';
import store from './store';
import Main from './views/Main.vue';
import Translations, { trans } from './lib/Translations';


window.trans = trans;

Vue.use(VeeValidate);
Vue.use(Vuetify);
Vue.use(VueMarkdown);
Vue.component('vue-markdown', VueMarkdown); // Has to be initiated this way
Vue.mixin(Translations);

// Execute initial actions
store.dispatch('common/init');
store.dispatch('translations/init');

const app = new Vue({
  el: '#app',
  template: '<Main v-cloak />',
  router,
  store,
  computed: {
    ...mapGetters({
      info: 'common/info',
      locale: 'common/locale',
      currentLocale: 'common/currentLocale',
    }),
  },
  components: {
    Main,
  },

});
