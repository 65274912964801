<template>
  <v-container
    px-5
    py-3
    fluid
    class="app footer"
    tag="footer"
  >
    <v-layout
      row
      wrap
      align-end
    >
      <v-flex>
        <ul>
          <li>
            <router-link to="/info/imprint">
              {{ t('main.components.footer.imprint') }}
            </router-link>
          </li>
          <li>
            <router-link to="/info/contact">
              {{ t('main.components.footer.contact') }}
            </router-link>
          </li>
          <li>
            <router-link to="/info/privacy-policy">
              {{ t('main.components.footer.privacy') }}
            </router-link>
          </li>
        </ul>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'Footer',
};
</script>
