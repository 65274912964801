import Vue from 'vue';
import Vuex from 'vuex';
import RootStore from './vuex/RootStore';
import CommonStore from './vuex/CommonStore';
import IncidentFormStore from './vuex/IncidentFormStore';
import TranslationsStore from '../common/vuex/TranslationsStore';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    ...RootStore.state,
  },
  getters: {
    ...RootStore.getters,
  },
  mutations: {
    ...RootStore.mutations,
  },
  actions: {
    ...RootStore.actions,
  },
  modules: {
    common: CommonStore,
    incidentForm: IncidentFormStore,
    translations: TranslationsStore,
  },
});
