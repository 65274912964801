<template>
  <v-container
    fluid
    class="app navigation"
    tag="nav"
  >
    <v-layout
      row
      wrap
      fill-height
      justify-space-around
    >
      <v-flex
        xs12
        sm3
        class="nav-item"
        d-flex
      >
        <router-link to="/report">
          <vue-markdown :source="t('main.components.navigation.report')" />
        </router-link>
      </v-flex>
      <v-flex
        xs12
        class="nav-item"
        d-flex
      >
        <router-link to="/definition">
          <vue-markdown :source="t('main.components.navigation.definition')" />
        </router-link>
      </v-flex>
      <v-flex
        xs12
        class="nav-item"
        d-flex
      >
        <router-link to="/purpose">
          <vue-markdown :source="t('main.components.navigation.purpose')" />
        </router-link>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'Navigation',
};
</script>
