import has from 'lodash/has';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import isObject from 'lodash/isObject';
// import { trans } from "../../main/lib/Translations";

export function formStatus() {
  return {
    valid: true,
    errors: null,
    errorMessage: null,
    success: false,
    httpCode: null,
    timestamp: new Date().getTime(),
  };
}

function trans(val) {
  return val;
}

function extractMessage(data) {
  let serverMsg = has(data, 'message') ? trans(data.message) : null;
  const code = get(data, 'response.status', null);

  // Override message with general error (if exists)
  if (has(data, 'response.data.status')) {
    serverMsg = trans('main.error.server', { error: `${code}: ${data.response.data.status}` });
  }
  // Override message with data error (if exists)
  if (has(data, 'response.data.message')) {
    serverMsg = trans(data.response.data.message);
  }

  if (code === 422) {
    return trans('validation.error');
  }
  return serverMsg;
}

export function mutations(namespace) {
  return {
    [`${namespace}Success`](state, payload) {
      const status = has(payload, 'status') ? payload.status : null;
      const message = extractMessage(payload);

      state[namespace].valid = true;
      state[namespace].errorMessage = null;
      state[namespace].successMessage = message;
      state[namespace].errors = null;
      state[namespace].httpCode = status;
      state[namespace].success = true;
      state[namespace].timestamp = new Date().getTime();
    },
    [`${namespace}Error`](state, payload) {
      const errors = has(payload, 'response.data.errors') ? payload.response.data.errors : null;
      const status = has(payload, 'response.status') ? payload.response.status : null;
      const message = extractMessage(payload);

      state[namespace].valid = false;
      state[namespace].success = false;
      state[namespace].errorMessage = message;
      state[namespace].successMessage = null;
      state[namespace].errors = errors;
      state[namespace].httpCode = status;
      state[namespace].timestamp = new Date().getTime();
    },
  };
}

/**
 * Function meant to be run as watcher handler inside components to handle FormStatus object
 * @param newStatus
 */
export function statusHandler(newStatus) {
  if (newStatus.success === false) {
    const responseErrors = newStatus.errors;
    const message = newStatus.errorMessage || 'common.error.unknown';

    if (isObject(responseErrors)) {
      const errorFields = Object.keys(responseErrors);

      forEach(errorFields, (f) => {
        const nameArray = f.split('.');
        const fieldName = nameArray[0];
        const field = this.$validator.fields.find({ name: fieldName, scope: this.$options.scope });
        // const field = this.$validator.fields.find({ name: "name" });

        if (!field) return;

        this.$validator.errors.add({
          id: field.id,
          field: fieldName,
          msg: responseErrors[f],
          scope: this.$options.scope,
        });

        field.setFlags({
          invalid: true,
          valid: false,
          validated: true,
        });
      });
    }

    this.$store.dispatch('message', { body: message, type: 'error' });
  } else {
    const message = newStatus.successMessage || 'common.form.success';
    this.$store.dispatch('message', { body: message, type: 'success' });
  }
}
