<template>
  <v-app v-cloak>
    <v-content class="fill-height">
      <Header />
      <Navigation />
      <v-container
        px-5
        fluid
        class="app content"
        tag="main"
      >
        <v-layout row>
          <v-flex>
            <router-view />
          </v-flex>
        </v-layout>
      </v-container>
      <Footer />
    </v-content>
    <div
      v-if="loading"
      class="overlay"
    >
      <v-progress-linear :indeterminate="true" />
    </div>
    <Messages :message="message" />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';
import Navigation from '../components/Navigation.vue';
import Messages from '../../common/components/Messages';

export default {
  name: 'Main',
  computed: {
    ...mapGetters(['loading', 'message']),
  },
  components: {
    Messages,
    Header,
    Footer,
    Navigation,
  },
};
</script>

<style>
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { /* IE10+ */
        #app > .application--wrap > main.fill-height > div {
            flex-basis: 100%;
        }
    }
</style>
