import Api from './Api';
import { ADMIN_ENDPOINTS } from '../config/api';

export default class Translations extends Api {
  get(data) {
    return super.post({
      call: ADMIN_ENDPOINTS.TRANSLATIONS_GET,
      data,
    });
  }

  update(data) {
    return super.put({
      call: ADMIN_ENDPOINTS.TRANSLATIONS_UPDATE,
      data: {
        items: data,
      },
    });
  }

  listGroups() {
    return super.get({
      call: ADMIN_ENDPOINTS.TRANSLATIONS_LIST_GROUPS,
    });
  }

  importTranslations(data) {
    const formData = new FormData();
    formData.append('locale', data.locale || '');
    formData.append('file', data.file[0].file, data.file[0].name);

    return super.post({
      call: ADMIN_ENDPOINTS.TRANSLATIONS_IMPORT,
      data: formData,
    });
  }
}
