<template>
  <v-select
    class="language-switch"
    :model="selectedLocale"
    :items="items"
    :label="label"
    item-text="label"
    item-value="lang"
    :menu-props="{maxWidth:150}"
    single-line
    @change="setLocale"
  />
</template>


<script>
import cookies from 'browser-cookies';
import { mapGetters } from 'vuex';
import ucfirst from 'lodash/startCase';
import { trans } from '../../main/lib/Translations';

export default {
  name: 'LanguageSwitch',
  data() {
    return {
      selectedLocale: null,
      label: null,
    };
  },
  computed: {
    ...mapGetters({
      currentLocale: 'common/currentLocale',
      items: 'common/locale',
    }),
  },
  watch: {
    currentLocale(val) {
      this.setLabel(val);
    },
  },
  created() {
    this.setLabel(this.currentLocale);
  },
  methods: {
    setLocale(payload) {
      cookies.set('locale', payload);
      this.$store.dispatch('common/changeLocale', payload);
    },
    setLabel(value) {
      this.label = ucfirst(trans(`main.components.header.locales.${value}`));
    },
  },
};
</script>
