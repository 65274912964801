<template>
  <article>
    <div class="definition">
      <h4>{{ t('main.views.definition.definitions.header') }}</h4>
      <vue-markdown :source="t('main.views.definition.definitions.text')" />
    </div>

    <vue-markdown :source="t('main.views.definition.text')" />
  </article>
</template>

<script>
export default {
  name: 'Definition',
};
</script>
