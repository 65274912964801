<template>
  <article>
    <h1><vue-markdown :source="t(`main.views.${slug}.title`)" /></h1>
    <vue-markdown :source="t(`main.views.${slug}.text`)" />
  </article>
</template>

<script>
export default {
  name: 'InfoPages',
  computed: {
    slug() {
      return this.$route.params.slug || null;
    },
  },
};
</script>
