import axios from 'axios';
import cookies from 'browser-cookies';
import get from 'lodash/get';
import { API_URL, ROOT_STORE } from '../config/api';

const config = {};

const getToken = () => cookies.get('token');

export default class Api {
  get({ ...args }) {
    if (!args.call) {
      console.error('API ERROR - no call parameter');
      return false;
    }

    const config = get(args, 'config', {});
    const token = getToken();

    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    }

    if (args.data) {
      config.params = args.data;
    }

    return new Promise((resolve, reject) => {
      axios.get(`${API_URL}${args.call}`, config)
        .then((response) => {
          if (response) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  put({ ...args }) {
    if (!args.call) {
      console.error('API ERROR - no call parameter');
      return false;
    }

    const data = (args.data) ? args.data : null;
    const config = get(args, 'config', {});

    if (this.token) {
      config.headers = {
        Authorization: `Bearer ${getToken()}`,
      };
    }

    return new Promise((resolve, reject) => {
      axios.put(`${API_URL}${args.call}`, data, config)
        .then((response) => {
          if (response) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  post({ ...args }) {
    if (!args.call) {
      console.error('API ERROR - no call parameter');
      return false;
    }

    const data = (args.data) ? args.data : null;
    const config = get(args, 'config', {});

    if (this.token) {
      config.headers = {
        Authorization: `Bearer ${getToken()}`,
      };
    }

    return new Promise((resolve, reject) => {
      axios.post(`${API_URL}${args.call}`, data, config)
        .then((response) => {
          if (response) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  delete({ ...args }) {
    if (!args.call) {
      console.error('API ERROR - no call parameter');
      return false;
    }

    const config = get(args, 'config', {});
    const token = getToken();

    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    }

    if (args.data) {
      config.params = args.data;
    }

    return new Promise((resolve, reject) => {
      axios.delete(`${API_URL}${args.call}`, config)
        .then((response) => {
          if (response) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });


    // return axios.delete(API_URL + call, config);
  }

  loadingStart(storeContext) {
    if (typeof storeContext === 'function') {
      storeContext.commit(ROOT_STORE.MUTATIONS.LOADING, true, { root: true });
    }
  }

  loadingEnd(storeContext) {
    if (typeof storeContext === 'function') {
      storeContext.commit(ROOT_STORE.MUTATIONS.LOADING, false, { root: true });
    }
  }

  loadingFailure(storeContext) {
    if (typeof storeContext === 'function') {
      this.loadingEnd(storeContext);
      storeContext.commit(ROOT_STORE.MUTATIONS.FAILED, null, { root: true });
    }
  }
}
