import isString from 'lodash/isString';
import Translations from '../../api/Translations';

const api = new Translations();

export default {
  namespaced: true,
  state: {
    translations: [],
    groups: [],
  },
  getters: {
    get: (state) => (payload) => (isString(state.translations[payload]) ? state.translations[payload] : payload),
    getGroups(state) {
      return state.groups;
    },
    all(state) {
      return state.translations;
    },
  },
  mutations: {
    set(state, payload) {
      state.translations = payload;
    },
    setGroups(state, payload) {
      state.groups = payload;
    },
  },
  actions: {
    async init(context) {
      await context.commit('set', window.locale);
    },
    change(context, payload) {
      context.commit('set', payload);
    },
    listGroups(context) {
      context.dispatch('loading', true, { root: true });
      api.listGroups().then((response) => {
        context.commit('setGroups', response.data);
        context.dispatch('loading', false, { root: true });
      }).catch((error) => {
        context.dispatch('loading', false, { root: true });
      });
    },
  },
};
