import DefaultRootStore from '../../common/vuex/DefaultRootStore';

const rootStore = new DefaultRootStore();

export default {
  state: rootStore.state(),
  actions: rootStore.actions(),
  mutations: rootStore.mutations(),
  getters: rootStore.getters(),
};
