import Vue from 'vue';
import VueRouter from 'vue-router';
import get from 'lodash/get';
import Report from './views/Report';
import ReportSuccess from './views/ReportSuccess';
import Purpose from './views/Purpose';
import Definition from './views/Definition';
import InfoPages from './views/InfoPages';
import NotFound from '../common/components/NotFound';
import store from './store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Report,
    meta: {
      title: 'main.views.report.title',
    },
  },
  {
    path: '/report-success',
    component: ReportSuccess,
    meta: {
      title: 'main.views.report.title',
    },
  },
  {
    path: '/report',
    component: Report,
    meta: {
      title: 'main.views.report.title',
    },
  },
  {
    path: '/purpose',
    component: Purpose,
    meta: {
      title: 'main.views.purpose.title',
    },
  },
  {
    path: '/definition',
    component: Definition,
    meta: {
      title: 'main.views.definition.title',
    },
  },
  {
    path: '/info/:slug',
    component: InfoPages,
    meta: {
      title: {
        contact: 'main.views.contact.title',
        imprint: 'main.views.imprint.title',
        'privacy-policy': 'main.views.privacy-policy.title',
      },
    },
  },
  {
    path: '*',
    component: NotFound,
    meta: {
      title: 'Page not found',
    },
  },

];

const router = new VueRouter({
  routes,
  // mode: 'history'
});

/**
 * Checks if there's path stored, if so, pushes it into the router
 */
const checkPath = (next) => {
  const lastPath = store.getters.getLastPath;
  if (lastPath) {
    store.dispatch('setLastPath', null, { root: true });
    next(lastPath);
  }
};

// Hook fired bofore each route
router.beforeEach((to, from, next) => {
  function proceed() {
    const { loading } = store.getters;
    const settings = store.getters['common/settings'];

    if (!loading && settings) {
      let title = trans('main.head.title');
      const slug = get(to, 'params.slug', null);

      if (get(to, 'meta.title', null)) {
        if (slug) {
          if (get(to, `meta.title.${slug}`, null)) {
            title = `${trans(to.meta.title[slug])} - ${title}`;
          } else {
            next('/404');
          }
        } else {
          title = `${trans(to.meta.title)} - ${title}`;
        }
      }
      document.title = title;

      next();
    } else {
      // Set path that we wasn't allowed to enter
      store.dispatch('setLastPath', to.path, { root: true });
    }
  }

  store.watch(
    (state) => state.loading,
    (value) => {
      if (value.length === 0) {
        checkPath(next);
      }
    },
  );

  store.watch(
    (state) => state.common,
    (value) => {
      if (value.length !== null) {
        checkPath(next);
      }
    }, {
      deep: true,
    },
  );

  proceed();
});

export default router;
