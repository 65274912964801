<template>
  <article>
    <vue-markdown :source="t('main.views.purpose.text')" />
  </article>
</template>

<script>
export default {
  name: 'Purpose',
};
</script>
