<template>
  <article>
    <h1><vue-markdown :source="t('main.views.notfound.title')" /></h1>
    <vue-markdown :source="t('main.views.notfound.text')" />
  </article>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>
