import get from 'lodash/get';

export default class DefaultRootStore {
  // @TODO change to class
  state() {
    return {
      loading: [],
      message: {
        body: null,
        type: null,
        timestamp: null,
      },
      route: {
        current: null,
        previous: null,
      },
      lastPath: null,
    };
  }

  getters() {
    return {
      loading(state) {
        if (state.loading.length > 0) {
          return true;
        }
        return false;
      },
      message(state) {
        return state.message;
      },
      route(state) {
        return state.route;
      },
      getLastPath(state) {
        return state.lastPath;
      },

    };
  }

  mutations() {
    return {
      loading(state, payload) {
        if (payload === true) {
          state.loading.push(payload);
          return;
        }

        if (state.loading.length > 0) {
          state.loading.shift();
        }
      },
      setRoute(state, payload) {
        state.route = {
          current: get(payload, 'current', null),
          previous: get(payload, 'previous', null),
        };
      },
      setLastPath(state, payload) {
        state.lastPath = payload;
      },

    };
  }

  actions() {
    return {
      setLastPath(context, payload) {
        context.commit('setLastPath', payload);
      },
      route(context, payload) {
        context.commit('setRoute', payload);
      },
      loading(context, payload) {
        context.commit('loading', payload);
      },
      message(context, payload) {
        const msg = get(payload, 'body', null);
        const msgType = get(payload, 'type', null);
        const msgArgs = get(payload, 'args', null);
        const error = get(payload, 'error', null);
        let type = null;

        switch (msgType) {
          case 'success':
            type = 'success';
            break;
          case 'info':
            type = 'info';
            break;
          case 'error':
            type = 'error';
            break;
          default:
            break;
        }

        // Supress messages for 401, as it's being handled by router
        if (get(error, 'response.status') !== 401) {
          context.state.message.body = trans(msg, msgArgs);
          context.state.message.type = type;
          context.state.message.timestamp = new Date().getTime();
        }
      },
    };
  }
}
