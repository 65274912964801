<template>
  <article>
    <vue-markdown :source="t('main.views.report.form.success')" />
  </article>
</template>

<script>
export default {
  name: 'ReportSuccess',
};
</script>
