export const API_URL = '/api/';

export const ENDPOINTS = {
  CLIENT_INIT: 'client/init',
  INCIDENT_CREATE: 'new-incident',
  CLIENT_CHANGE_LOCALE: 'client/change-locale',
};

export const ADMIN_ENDPOINTS = {
  AUTH_CHECK: 'auth/check',
  AUTH_LOGIN: 'auth/login',
  AUTH_LOGOUT: 'auth/logout',

  USERS_LIST: 'users',
  USERS_FORM_LIST: 'users/form',
  USERS_LIST_GRP_ADMIN: 'users/grp-admin',
  USERS_LIST_CNT_ADMIN: 'users/cnt-admin',
  USERS_LIST_IT_ADMIN: 'users/it-admin',
  USERS_GET: 'users',
  USERS_DELETE: 'users',
  USERS_CREATE: 'users/register',
  USERS_UPDATE: 'users',
  USERS_UPDATE_PROFILE: 'users/update-profile',
  USERS_UNASSIGN: 'users/unassign-group',

  GROUPS_LIST: 'groups',
  GROUPS_GET: 'groups',
  GROUPS_CREATE: 'groups/create',
  GROUPS_UPDATE: 'groups',
  GROUPS_DELETE: 'groups',

  CATEGORIES_LIST: 'categories',
  CATEGORIES_GET: 'categories',
  CATEGORIES_CREATE: 'categories/create',
  CATEGORIES_UPDATE: 'categories',
  CATEGORIES_DELETE: 'categories',

  SETTINGS_UPDATE: 'settings/update',

  COUNTRIES_LIST: 'countries',

  TRANSLATIONS_LIST_GROUPS: 'translations/groups',
  TRANSLATIONS_GET: 'translations/get',
  TRANSLATIONS_UPDATE: 'translations',
  TRANSLATIONS_IMPORT: 'translations/import',

  INCIDENTS_LIST: 'incidents',
  INCIDENTS_GET: 'incidents',
  INCIDENTS_DELETE: 'incidents',
  INCIDENTS_CREATE: 'incidents/create',
  INCIDENTS_UPDATE: 'incidents',
  INCIDENTS_LIST_MOTIVATIONS: 'incidents/motivations',
  INCIDENTS_LIST_INDICATORS: 'incidents/indicators',
  INCIDENTS_LIST_TYPES: 'incidents/types',
  INCIDENTS_SET_STATUS: 'incidents/set-status',

  FILES_DELETE: 'files',

  ACTIONS_CREATE: 'actions/create',
  ACTIONS_LIST: 'actions/incident',
  ACTIONS_DELETE: 'actions',

  LOGS_LIST: 'logs',


};

export const ROOT_STORE = {
  MUTATIONS: {
    LOADING: 'loading',
    FAILED: 'apiError',
  },
};
