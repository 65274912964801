import forEach from 'lodash/forEach';

const defaultMaxSize = 20000;

export default {
  getMessage(field, args) {
    const megabytes = ((args[0]) ? args[0] : defaultMaxSize) / 1000;
    return trans('validation.total_filesize_exceeded', { attribute: megabytes });
  },
  validate(value, args) {
    const max = (args[0]) ? args[0] : defaultMaxSize;

    let currentSize = 0;
    forEach(value, (file) => {
      currentSize += file.size;
    });

    if (currentSize / 1000 > max) {
      return false;
    }

    return true;
  },
};
