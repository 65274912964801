<template>
  <v-container
    fluid
    class="app header"
    tag="header"
  >
    <v-layout
      col
      align-end
    >
      <v-flex class="app-name">
        <img
          src="/assets/main/img/logo.png"
          :title="t('main.head.title')"
        >
        <h2>{{ t('main.head.title') }}</h2>
      </v-flex>
      <v-spacer />
      <v-flex
        xs3
        md2
        lg2
        pr-5
      >
        <LanguageSwitch />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import LanguageSwitch from '../../common/components/LanguageSwitch.vue';

export default {
  name: 'Header',
  components: {
    LanguageSwitch,
  },
};
</script>

<style scoped>
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { /* IE10+ */
        .spacer {
            width: 1px;
        }
    }
</style>
