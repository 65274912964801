import Api from './Api';
import { ENDPOINTS } from '../config/api';

export default class Client extends Api {
  getInitialData(data) {
    return super.post({
      call: ENDPOINTS.CLIENT_INIT,
      data,
    });
  }

  changeLocale(data) {
    return super.post({
      call: ENDPOINTS.CLIENT_CHANGE_LOCALE,
      data,
    });
  }
}
