import get from 'lodash/get';
import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import Api from '../Api';
import { ENDPOINTS } from '../../config/api';

export default class Incident extends Api {
  create(data) {
    const formData = new FormData();
    formData.append('reCaptcha', data.reCaptcha || '');
    formData.append('date', data.incident.date || '');
    formData.append('location', JSON.stringify(data.incident.location || ''));
    formData.append('locationDisplay', data.incident.locationDisplay || '');
    formData.append('summary', data.incident.summary || '');
    formData.append('type', data.reporter.type || '');
    formData.append('agreement', data.reporter.agreement || '');
    formData.append('description', data.reporter.description || '');
    formData.append('email', data.reporter.email || '');
    formData.append('contactPolice', data.reporter.contactPolice || '');
    formData.append('name', data.reporter.name || '');
    formData.append('custom_text', data.custom.text || '');
    formData.append('custom_checkbox', data.custom.checkbox || '');


    const files = data.files || [];

    if (isArray(files)) {
      forEach(files, (item) => {
        formData.append('files[]', item.file, item.name);
      });
    }

    return super.post({
      call: ENDPOINTS.INCIDENT_CREATE,
      data: formData,
    });
  }
}
