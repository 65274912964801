<template>
  <v-snackbar
    v-model="show"
    bottom
    multi-line
    :color="message.type"
    :timeout="timeout"
  >
    {{ message.body }}
    <button
      class="message-btn"
      @click="show = false"
    >
      Close
    </button>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Messages',
  props: {
    message: {
      type: Object,
      default: () => ({
        body: null,
        type: 'default',
        timestamp: null,
      }),
    },
  },
  data() {
    return {
      show: false,
      timeout: 7000,
      timestamp: null,
    };
  },
  watch: {
    message: {
      deep: true,
      handler(val) {
        if (val.timestamp !== this.timestamp) {
          this.show = true;
          this.timestamp = val.timestamp;
        }
      },
    },
  },
};
</script>
