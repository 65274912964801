import { reloadTranslations } from '../lib/Translations';
import DefaultCommonStore from '../../common/vuex/DefaultCommonStore';

const commonStore = new DefaultCommonStore();

export default {
  namespaced: true,
  state: commonStore.state(),
  getters: commonStore.getters(),
  mutations: commonStore.mutations(),
  actions: commonStore.actions(reloadTranslations),
};
