<template>
  <v-menu
    v-model="datePickerMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    lazy
    transition="scale-transition"
    offset-y
    full-width
    max-width="290px"
    min-width="290px"
  >
    <v-text-field
      slot="activator"
      v-model="computedDateFormatted"
      :label="label"
      :hint="hint"
      :persistent-hint="persistentHint"
      :error-messages="errorMessages"
      :error="error"
      append-icon="event"
      readonly
    />
    <v-date-picker
      v-model="dateRaw"
      :max="dateNow"
      no-title
      @input="datePickerMenu = false"
    />
  </v-menu>
</template>

<script>
export default {
  name: 'DatePicker',
  props: {
    value: {
      type: String,
      default: null,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: String | Array,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      datePickerMenu: false,
      dateRaw: null,
      dateFormatted: null,
    };
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.dateRaw);
    },
    dateNow() {
      return new Date().toISOString();
    },
  },

  watch: {
    dateRaw(val) {
      this.dateFormatted = this.formatDate(val);
      this.$emit('dateChanged', val);
    },
    value(val) {
      this.dateRaw = val;
    },
  },
  created() {
    this.dateRaw = this.value;
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
  },
};
</script>
