<template>
  <article>
    <vue-markdown :source="t('main.views.report.text', {'ngo' : 'common.general.ngo'})" />
    <VisitorIncidentForm />
  </article>
</template>

<script>
import VisitorIncidentForm from './forms/VisitorIncidentForm';

export default {
  name: 'Report',
  components: {
    VisitorIncidentForm,
  },
};
</script>
